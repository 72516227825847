<!-- 列表分页 -->
<template>
  <div class="goods_el_table_pagination" v-if="isXmPagel">
    <div
      class="goods_pagination_box"
      :class="currentpage1 == pages ? 'activeColor' : ''"
    >
      <div
        class="btn_style"
        @click="goPage(-1)"
        :class="pages == 1 ? 'prohibited' : ''"
      >
        <svg
          t="1680268631475"
          class="icon arrowsIcon spinBtn"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2985"
          width="16"
          height="16"
        >
          <path
            d="M670.208 481.792a42.666667 42.666667 0 0 1 0 60.416l-256 256a42.666667 42.666667 0 0 1-60.416-60.416L579.669333 512 353.792 286.208a42.666667 42.666667 0 0 1 60.416-60.416l256 256z"
            fill="#999999"
            p-id="2986"
          ></path>
        </svg>
        上一页
      </div>
      <el-pagination
        @current-change="currentChange"
        :current-page="pages"
        background
        :pager-count="5"
        layout=" pager"
        :prev-text="'上一页'"
        :next-text="'下一页'"
        :total="total"
        :page-size="pageSize"
      ></el-pagination>
      <div
        class="btn_style"
        @click="goPage(1)"
        :class="pages == totalPageNum() ? 'prohibited' : ''"
      >
        下一页
        <svg
          t="1680268631475"
          class="icon arrowsIcon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2985"
          width="16"
          height="16"
        >
          <path
            d="M670.208 481.792a42.666667 42.666667 0 0 1 0 60.416l-256 256a42.666667 42.666667 0 0 1-60.416-60.416L579.669333 512 353.792 286.208a42.666667 42.666667 0 0 1 60.416-60.416l256 256z"
            fill="#999999"
            p-id="2986"
          ></path>
        </svg>
      </div>
      <div class="page_all">共{{ totalPageNum() }}页</div>
      <div class="page_style">, 跳转到</div>
      <input type="text" class="paginationInput" v-model="currentpage" />
      <div class="page_style">页</div>
      <button class="paginationBtn" @click="goPageBtn">确定</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'pagTools',
  data() {
    return {
      total: this.curTotal,
      pages: this.curPages,
      pageSize: this.curPageSize,
      currentpage: '',
      allPage: '', //总页数
      currentpage1: 1,
    };
  },
  props: {
    curTotal: {
      type: Number,
      default: 0,
    },
    curPageSize: {
      type: Number,
      default: 10,
    },
    curPages: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    currentpage: function (n) {
      if (!n) {
        return;
      }
      if (Number(n) < this.allPage) {
        this.currentpage = Number(n);
      } else {
        this.currentpage = this.allPage;
      }
    },
    curPages: function (page) {
      if (page) {
        this.currentpage = '';
        this.pages = page;
      }
    },
    curTotal: function (total) {
      this.total = Number(total);
    },
    curPageSize: function (pageSize) {
      this.pageSize = Number(pageSize);
    },
  },
  computed: {
    isXmPagel() {
      if (this.curTotal > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // 总页数
    totalPageNum() {
      if (this.total == null || this.total == '') {
        return 0;
      } else {
        if (this.pageSize != 0 && this.total % this.pageSize == 0) {
          this.allPage = parseInt(this.total / this.pageSize);
          return parseInt(this.total / this.pageSize);
        }
        if (this.pageSize != 0 && this.total % this.pageSize != 0) {
          this.allPage = parseInt(this.total / this.pageSize) + 1;
          return parseInt(this.total / this.pageSize) + 1;
        }
      }
    },
    goPageBtn() {
      if (!this.currentpage) {
        this.$message({
          type: 'warning',
          message: '请选择要跳转的页码',
        });
        return;
      }
      this.pages = Number(this.currentpage);
      this.currentpage1 = this.pages;
      this.$emit('goPageBtn', this.pages);
    },
    currentChange(n) {
      this.pages = n;
      this.currentpage1 = this.pages;
      this.$emit('currentChange', n);
    },
    goPage(n) {
      if (this.total === 0) {
        return;
      }
      this.pages += n;
      this.currentpage1 = this.pages;
      this.$emit('goPageNum', this.pages);
    },
    // 回到初始页面 加ref调用该方法
    changecurPage(n = 1) {
      this.pages = n;
    },
  },
};
</script>
<style scoped>
.goods_el_table_pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.goods_el_table_pagination span {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-right: auto;
}

.goods_pagination_box {
  display: flex;
  align-items: center;
}
.goods_pagination_box span {
  width: 40px;
  height: 28px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.paginationInput {
  width: 32px;
  height: 32px;
  text-align: center;
  outline: none;
  border: 1px solid #808080;
  margin: 0 10px;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #808080;
  box-sizing: border-box;
}
.paginationBtn {
  width: 48px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #808080;
  margin-left: 14px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.goods_pagination_box >>> .btn-prev span {
  border-radius: 4px 4px 4px 4px;
}

.btn_style {
  width: 78px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none; /*moziLar*/
  -webkit-user-select: none; /*webkit*/
  -ms-user-select: none; /*IE*/
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #808080;
}
.page_all {
  margin-left: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.page_style {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.prohibited {
  pointer-events: none;
}
.arrowsIcon {
  vertical-align: middle;
}
.spinBtn {
  transform: rotate(180deg);
}
</style>
