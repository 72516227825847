// 注册全局组件
import Vue from 'vue';
import IndexBottom from '@/components/indexbottom/indexbottom.vue'; //底部
import XmTitle from '@/components/xmtitle/index.vue'; //标题
import Header from '@/components/Header.vue'; //顶部
import XmDialog from '@/components/XmDialog.vue'; //带有插槽的弹窗
import XmTip from '@/components/XmTip.vue'; // 提示
import XmPreviewimg from '@/components/XmPreviewimg.vue'; // 预览图片/视频
import XmDeleteConfig from '@/components/XmDeleteConfig.vue'; //删除确认框
import XmPagel from '@/components/XmPagel.vue'; // 列表分页
import XmUpload from '@/components/XmUpload.vue'; // 上传组件
import XmPreviewdoc from '@/components/XmPreviewdoc.vue'; // 预览文档
import XmPreviewImage from '@/components/XmPreviewImage.vue'; // 悬浮小图预览
import AreaSelect from '@/components/AreaSelect.vue'; // 地址选择器
import XmShowFileBox from '@/components/XmShowFileBox.vue'; // 展示已上传的文件

const components = {
  IndexBottom,
  XmTitle,
  Header,
  XmTip,
  XmDialog,
  XmPreviewimg,
  XmDeleteConfig,
  XmPagel,
  XmUpload,
  XmPreviewdoc,
  XmPreviewImage,
  AreaSelect,
  XmShowFileBox,
};
export function initGlobalComponents() {
  Object.keys(components).forEach((component_name) => {
    Vue.component(component_name, components[component_name]);
  });
}
