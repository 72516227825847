<template>
  <div class="bottompage">
    <div class="wrap">
      <!-- 版权 -->
      <div class="policy">
        <div class="about">
          <span>关于我们</span>
          <span>联系客服</span>
          <span>合作招商</span>
          <span>商家帮助</span>
          <span>隐私政策</span>
          <span>语言</span>
          <span>廉正举报</span>
        </div>
        <div class="copyright">
          <span @click="goUrl(1)">湘ICP备2023010376号-1</span>
          <span @click="goUrl(2)">湘公网备案 43019002001936</span>
          <span @click="tolicense">营业执照</span>
          <span>增值电信业务经营许可证</span>
          <span>Copyright © 2023-2033 ximu.cn 版权所有</span>
          <span>违法和不良信息举报电话：0755-27761552</span>
        </div>
        <div class="bottomimg">
          <!-- <span><img src="../assets/bottom_1.png" alt=""></span>
                    <span><img src="../assets/bottom_2.png" alt=""></span>
                    <span><img src="../assets/bottom_3.png" alt=""></span>
                    <span><img src="../assets/bottom_4.png" alt=""></span>
                    <span><img src="../assets/bottom_5.png" alt=""></span>
                    <span><img src="../assets/bottom_6.png" alt=""></span> -->
          <span @click="authen(1)"
            ><img src="@/assets/bottom_1.svg" alt=""
          /></span>
          <span @click="authen(2)"
            ><img src="@/assets/bottom_2.svg" alt=""
          /></span>
          <span @click="authen(3)"
            ><img src="@/assets/bottom_3.svg" alt=""
          /></span>
          <span @click="authen(4)"
            ><img src="@/assets/bottom_4.svg" alt=""
          /></span>
          <span @click="authen(5)"
            ><img src="@/assets/bottom_5.svg" alt=""
          /></span>
          <span @click="authen(6)"
            ><img src="@/assets/bottom_6.svg" alt=""
          /></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IndexBottom',
  data() {
    return {};
  },
  methods: {
    goUrl(index) {
      switch (index) {
        case 1:
          window.open('https://beian.miit.gov.cn/#/Integrated/index');
          break;
        case 2:
          window.open(
            'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001936'
          );
          break;
        default:
          break;
      }
    },
    authen(index) {
      // 可信网站
      if (index == 1) {
      }
      // 诚信网站
      else if (index == 2) {
      }
      // 12315
      else if (index == 3) {
        window.open('https://www.12315.cn/', '_blank');
      }
      // 网络警察提醒
      else if (index == 4) {
        window.open('http://www.cyberpolice.cn/wfjb/', '_blank');
      }
      // 网络有害举报
      else if (index == 5) {
        window.open('https://www.12377.cn/', '_blank');
      }
      // 国家知识产权
      else if (index == 6) {
      }
    },
    tolicense() {
      window.open(
        'https://img.ximu.cn/test_upload_ZH12304010001_169094288685503563658',
        '_blank'
      );
    },
  },
};
</script>
<style scoped>
.bottompage {
  /* background-color: #fff; */
}
.bottompage .wrap {
  margin: 0 auto;
  /* width: 1200px; */
}

/* 特色服务 */
.features {
  display: flex;
  padding: 30px;
  justify-content: space-between;
  /* border-bottom: 1px dashed #E6E6E6; */
}
.features .server {
  display: flex;
  align-items: center;
}
.server .serverimg {
  margin-right: 21px !important;
  width: 60px;
  height: 60px;
  font-size: 60px;
  color: #666666;
  font-weight: 100;
}
.server .serverfont {
  width: 162px;
  height: 24px;
  font-size: 18px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  line-height: 24px;
}

/* 指南 */
.guide {
  padding: 50px 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.logoimg {
  float: left;
  display: flex;
  align-items: center;
  margin: 16px 100px 0 16px;
  /* margin-right: 100px; */
  /* padding: 16px 0; */
}

.logo {
  position: relative;
  width: 80px;
  height: 80px;
}

.logo .iconfont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ff7802;
  font-size: 45px;
}

.logofont {
  position: relative;
  margin-left: 4px;
  width: 90px;
  height: 90px;
}

.logofont .iconfont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ff7802;
  font-size: 31px;
}

/* .logoimg div:first-child {
    padding: 27px 8px 26px 9px;
    width: 100px;
    height: 100px;
} */

/* .logoimg div:first-child img {
    width: 100%;
} */

.guidecontent {
  display: flex;
  justify-content: space-between;
}

.guidelist {
  display: flex;
  flex-direction: column;
  color: #808080;
  font-size: 12px;
}

.guidelist span {
  margin-top: 10px;
}

.guidelist span:first-child {
  margin-top: 0;
  margin-bottom: 10px;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: bold;
}

/* 版权 */

.policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #808080;
  padding: 20px 0 50px 0;
}

.about span::after,
.copyright span::after {
  content: '|';
  width: 1px;
  height: 8px;
  margin: 0 4px;
}

.about span:last-child::after,
.copyright span:last-child::after {
  content: none;
}

.copyright {
  color: #999;
  padding: 22px 0 20px;
}

.copyright span {
  cursor: pointer;
}
.about span {
  cursor: pointer;
}
.bottomimg img {
  cursor: pointer;
}

.bottomimg {
  display: flex;
}
.bottomimg span {
  margin-right: 10px;
}

.bottomimg span:last-child {
  margin-right: 0;
}

.bottomimg span img {
  cursor: pointer;
}

/* 悬浮变色 */
.guidelist span:nth-child(n + 2):hover,
.about span:hover,
.copyright span:hover {
  color: #ff7802;
  cursor: pointer;
}

.about span:hover::after,
.copyright span:hover::after {
  color: #808080;
}
</style>
