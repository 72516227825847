import request from '../request';

// 获取国
export function getCountries(data) {
  return request({
    url: '/Address/getCountries',
    method: 'get',
    params: data,
  });
}

// 获取省
export function getStates(data) {
  return request({
    url: '/Address/getStates',
    method: 'get',
    params: data,
  });
}

// 获取市
export function getCity(data) {
  return request({
    url: '/Address/getCities',
    method: 'get',
    params: data,
  });
}
