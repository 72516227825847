<template>
  <!-- 标题组件 -->
  <div class="xm-title">
    <div class="content">
      {{ title }}
      <el-popover placement="bottom" trigger="hover" v-if="popContent">
        <div class="popover-text">{{ popContent }}</div>
        <img slot="reference" src="@/assets/images/tip.svg" />
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XmTitle',
  props: {
    title: {
      type: String,
    },
    // 提示的内容
    popContent: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-title {
  // height: 42px;
  // margin: 20px 0;
  padding: 20px 0;
  background: #f7f7f7;
  font-size: 24px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  line-height: 22px;
  color: #111111;

  .content {
    // display: flex;
    // align-items: center;

    ::v-deep .el-popover__reference-wrapper img {
      vertical-align: 2px;
      width: 20px;
      height: 20px;
    }
  }
}
</style>
