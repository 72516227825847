import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {},
  state: {
    currentsku: {},
  },
  getters: {},
  mutations: {
    STE_SKU:(state, data) => (state.currentsku = data),
  },
  actions: {
     setsku(context, sku) {
      context.commit('STE_SKU', sku);
    },
  },
});
