<template>
  <div class="xm-show-file-box">
    <div class="success-list" v-for="(item, index) in fileList" :key="index">
      <div class="left-side flex" @click="showPreviewimgFunc(item.link)">
        <XmPreviewImage
          v-if="item.type == 'image'"
          width="22px"
          height="22px"
          :imgSrc="item.preview"
        ></XmPreviewImage>
        <video v-if="item.type == 'video'" :src="item.preview"></video>
        <img
          v-if="item.type !== 'image' && item.type !== 'video'"
          :src="item.type | uploadFileType"
          @error="handleImgError"
          alt=""
        />
        <div class="file-name overflow-line" :title="item.name">
          {{ item.name }}
        </div>
      </div>
    </div>

    <!-- 预览图片 -->
    <XmPreviewimg
      :showurl="previewImgUrl"
      @closepreimg="previewCloseFunc"
      v-if="showPreviewimg"
    ></XmPreviewimg>
    <!-- 预览文件 -->
    <XmPreviewdoc
      :showurl="previewImgUrl"
      @closepredox="previewCloseFunc"
      v-if="showPreviewdoc"
    ></XmPreviewdoc>
  </div>
</template>
<script>
export default {
  name: 'XmShowFileBox',
  props: {
    /**
     * 需要展示的文件
     * 字符串
     * 逗号分隔
     * 要拼好路径的
     */
    showFile: {
      type: String,
      default: '',
    },
  },
  watch: {
    // 父组件传过来要展示的文件们
    showFile: {
      immediate: true,
      handler(val) {
        if (val !== '') {
          this.handleFile(val);
        }
      },
    },
  },
  data() {
    return {
      fileList: [], // 传递过来的文件数组

      // 预览相关
      showPreviewimg: false, // 预览图片弹窗标识
      previewImgUrl: '', // 预览图片

      showPreviewdoc: false, // 预览文档弹窗标识
    };
  },
  filters: {
    uploadFileType(val) {
      if (val == 'excel') {
        return require('@/assets/material/Excel.svg');
      }
      if (val == 'pdf') {
        return require('@/assets/material/pdf.svg');
      }
      if (val == 'word') {
        return require('@/assets/material/word.svg');
      }
      if (val == 'zip') {
        return require('@/assets/material/zip.svg');
      }
      if (val == 'ppt') {
        return require('@/assets/material/ppt.svg');
      }
    },
  },
  mounted() {},
  methods: {
    //预览图片回调
    showPreviewimgFunc(key) {
      let fileType = key.split('.').pop();
      let isImage = this.matchType(fileType).toLowerCase() == 'image';
      let isVideo = this.matchType(fileType).toLowerCase() == 'video';
      if (isImage || isVideo) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
      this.previewImgUrl = this.$options.filters.imgbaseurl(key);
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    // 处理传递的文件们
    handleFile(val) {
      console.log('【父组件传过来要展示的文件们】', val);
      let arr = val.split(',').map((item) => {
        const fileName = item.trim();
        const type = this.matchType(fileName.split('.').pop());
        const preview = fileName;
        const name = fileName.split('/').pop();
        const link = fileName.split('/').pop();

        return {
          type: type,
          preview: preview,
          name: name,
          link: link,
        };
      });
      this.fileList = arr;
    },
  },
};
</script>
<style lang="scss" scoped>
.xm-show-file-box {
  padding: 9px 0;

  .success-list {
    margin-top: 10px;

    .left-side {
      cursor: pointer;

      &:hover .file-name {
        color: #026bff;
      }

      img {
        width: 22px;
        height: 22px;
        object-fit: contain;
      }
    }
    .file-name {
      margin-left: 10px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  ::v-deep img {
    object-fit: contain !important;
  }
}
</style>
