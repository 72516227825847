<template>
  <div id="app">
    <router-view class="margin-auto"></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.margin-auto {
  // width: 1440px;
  margin: 0 auto;
  background: #ffffff;
}
//设置级联选择器选中背景以及字体颜色
::v-deep .el-cascader-panel {
  .in-checked-path {
    .el-cascader-node__label {
      color: #026bff;
    }
    background: rgba(2, 107, 255, 0.1);
  }
  .el-cascader-node__label {
    color: #808080;
  }
}
// 设置级联选择器悬浮样式
::v-deep .el-cascader-panel .el-cascader-node:hover {
  background: rgba(2, 107, 255, 0.1);
}
</style>
<style>
.el-select-dropdown__item:hover,
.el-select-dropdown__item.hover {
  color: #026bff;
}
</style>
