import dayjs from 'dayjs';
import axios, { Axios } from 'axios';
import IMG_ERROR_PLACEHOLDER from './errorImg';

// 过滤
let mixin = {
  filters: {
    // 图片过滤
    imgbaseurl(value) {
      if (!value) return '';
      return 'https://img.ximu.cn/' + value;
    },
    // 判断是否有值
    emptyVal(val) {
      if (val && val != '') {
        return val;
      } else {
        return '--';
      }
    },
  },
  methods: {
    /**
     * 图片加载失败占位
     */
    handleImgError(event) {
      const img = event.target;
      // 用 base64 编码图片作为占位图片，避免出现 onerror 死循环导致递归爆栈
      img.src = IMG_ERROR_PLACEHOLDER;
    },
    /**
     * 时间格式化函数
     * @param {*} time 时间戳
     * @param {*} formatStr 格式
     * @returns 按格式格式化后的字符串
     */
    formatTime: function (time, formatStr) {
      return dayjs(time).format(formatStr);
    },
    /**
     * 节流函数
     * @param Function func 节流目标函数
     * @param String delay 延迟时间
     * @returns
     */
    throttle: function (func, delay) {
      let timer;
      return function () {
        let context = this;
        let args = arguments;
        if (!timer) {
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, delay);
        }
      };
    },
    /**
     * 从 supplier 项目偷过来的使用示例
     *
        this.jsq = setInterval(() => {
          this.handle_deadline = this.countdown(
            res.data.list[0].handle_deadline * 1000
          );
          this.refund_deadline = this.countdown(
            res.data.list[0].refund_deadline * 1000
          );
          this.return_deadline = this.countdown(
            res.data.list[0].return_deadline * 1000
          );
          this.isTime = true;
        }, 1000);
     *
     * 回到顶部
     */
    scrollToTop: function () {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    //获取当前时间并计算时间差
    getNowTime(time) {
      var date3 = time;
      if (date3 <= 0) {
        // clearInterval(this.jsq)
        // this.jsq = ''
        return '超时';
      } else {
        // date3 = Math.abs(date3);
        //计算出相差天数
        var days = Math.floor(date3 / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        let surplusTime =
          days + '天' + hours + '时' + minutes + '分' + seconds + '秒';
        return surplusTime;
      }
    },
    // 倒计时 （跟上面配套使用，有问题改或说）
    // 自己定义一个setInterval 放进自己定义的变量里 进行倒计时 ，注：记得销毁计时器
    countdown(gettime) {
      // 需要传进来的时间戳  10位数就*1000
      const t1 = Date.parse(new Date());
      return gettime - t1 > 0 ? gettime - t1 : 0;
    },
    // 通过后缀名作文件判断
    matchType(fileName) {
      // 后缀获取
      var suffix = '';
      // 获取类型结果
      var result = '';
      try {
        var flieArr = fileName.split('.');
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = '';
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'jfif', 'webp'];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'image';
        return result;
      }
      // 匹配txt
      var txtlist = ['txt'];
      result = txtlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'txt';
        return result;
      }
      // 匹配 excel
      var excelist = ['xls', 'xlsx'];
      result = excelist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'excel';
        return result;
      }
      // 匹配 word
      var wordlist = ['doc', 'docx'];
      result = wordlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'word';
        return result;
      }
      // 匹配 pdf
      var pdflist = ['pdf'];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'pdf';
        return result;
      }
      // 匹配 ppt
      var pptlist = ['ppt', 'pptx'];
      result = pptlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'ppt';
        return result;
      }
      // 匹配 视频
      var videolist = ['mp4', 'm2v', 'mkv'];
      result = videolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'video';
        return result;
      }
      // 匹配 音频
      var radiolist = ['mp3', 'wav', 'wmv'];
      result = radiolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'radio';
        return result;
      }
      // 其他 文件类型
      result = 'other';
      return result;
    },
    //复制文本
    copyText(text) {
      try {
        const input = document.createElement('input');

        input.style.opacity = 0;
        input.value = text;

        // 将元素插入到页面
        document.body.appendChild(input);

        // input.select 只能在元素插入到页面后, 才生效
        input.select();

        // isCopy 是否拷贝成功
        // document.execCommand 方法是已经废弃的方法
        const isCopy = document.execCommand('Copy');

        // 拷贝完成后, 不要忘了删除之前插入的元素
        document.body.removeChild(input);
        this.$message({ type: 'success', message: '复制成功!' });
        return isCopy;
      } catch (err) {
        return false;
      }
    },
    //将逗号传参风格转换成数组
    analysisComma(imgString) {
      imgString = imgString || '';
      let arr = [];
      if (imgString.indexOf(',') != -1) {
        arr = imgString.split(',');
        return arr;
      }
      return imgString ? [imgString] : arr;
    },
    //下载文件(字符流)
    downloadFile(url, fileName) {
      let box = '/api' + url;
      axios({
        url: box,
        method: 'get',
        headers: { token: localStorage.getItem('token3') },
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data]);
        const objectURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
      });
    },
    // 链接下载
    down(url, fileName) {
      axios({
        url: url + '?attname=' + fileName,
        method: 'get',
        responseType: 'blob',
      }).then((res) => {
        const filestream = res.data; // 返回的文件流
        const blob = new Blob([filestream], {
          type: res.headers['content-type'],
        });
        const a = document.createElement('a');
        const href = window.URL.createObjectURL(blob); // 创建下载连接
        a.href = href;
        a.download = decodeURI(name);
        document.body.appendChild(a);
        // console.log(a)
        // window.open(a.href)
        a.click();
        document.body.removeChild(a); // 下载完移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
  },
};

export default mixin;
