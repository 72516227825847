import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
// TODO 后续配根据权限动态加载路由
/**
 * 1.子路由请放到相应的父路由下
 * 2.文件目录参考商品
 */
const routes = [
  {
    path: '/',
    component: () => import('@/pages/layout'), //框架
    redirect: '/login',
    children: [
      {
        path: '/my/account',
        name: 'myaccount',
        component: () => import('@/pages/my-account/index.vue'), //我的账号
        meta: {
          isLogOn: true, //是否需要登录才能访问
        },
      },
      {
        path: '/ipp/list',
        name: 'ippList',
        component: () => import('@/pages/my-ipp/IppList.vue'), //我的知识产权
      },
      {
        path: '/my/complaints',
        name: 'mycomplaints',
        component: () => import('@/pages/my-complaints/index.vue'), //我的投诉
      },
      {
        path: '/audit',
        name: 'audit',
        component: () => import('@/pages/audit/index.vue'), //账号审核中
        meta: {
          isLogOn: true, //是否需要登录才能访问
        },
      },
      {
        path: '/ipp/add/:ippId?',
        name: 'addIpp',
        component: () => import('@/pages/my-ipp/AddIpp.vue'), // 添加知识产权
        meta: {
          pname: 1,
        },
      },
      {
        path: '/ipp/detail/:ippId?',
        name: 'ippDetail',
        component: () => import('@/pages/my-ipp/IppDetail.vue'), // 查看知识产权详情
        meta: {
          pname: 1,
        },
      },
      {
        path: '/complaints/details/:id?',
        name: 'complaintsdetails',
        component: () => import('@/pages/complaints-details/index.vue'), //查看详情
        meta: {
          pname: 2,
        },
      },
      {
        path: '/obligee/information',
        name: 'obligeeInfo',
        meta: {
          isLogOn: true, //是否需要登录才能访问
          pname: 3,
        },
        component: () => import('@/pages/obligee-info/ObligeeInfo.vue'), // 权利人信息
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/index.vue'), //登录
    meta: {
      isLogOn: false, //是否需要登录才能访问
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/pages/registration/index.vue'), //注册
    meta: {
      isLogOn: false, //是否需要登录才能访问
    },
  },
  {
    path: '/forgetpassword',
    name: 'forgetpassword',
    component: () => import('@/pages/forget-pass/index.vue'), //忘记密码
    meta: {
      isLogOn: false, //是否需要登录才能访问
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes: routes,
});

router.beforeEach((to, from, next) => {
  // todo 修改状态到时放到vuex
  let status = localStorage.getItem('statusShow');
  console.log(to, 'to');
  // 需要登录才能访问
  if (to.meta.isLogOn) {
    // if (to.fullPath == '/login') {
    //   next();
    // } else {
    //   next('/login');
    // }
    if (status == '-1' && to.path.indexOf('/obligee/information') === -1) {
      next('/obligee/information');
    } else if (!status) {
      next('/login');
    } else if (status == '1' && to.path.indexOf('/audit') === -1) {
      next('/audit');
    } else if (
      status == '3' &&
      to.path.indexOf('/obligee/information') === -1
    ) {
      next('/obligee/information');
    } else {
      next();
    }
  } else {
    next();
  }
});
// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
