<template>
  <div class="configmodel">
    <el-dialog
      :title="title"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :visible.sync="deleteDialogVisible"
      width="480px"
      :before-close="closeDeletedig"
    >
      <div>
        <div class="content">
          <div class="imgmodel" v-if="showTipPic">
            <img v-if="type == 'red'" src="@/assets/images/redtip.svg" />
            <img v-if="type == 'yellow'" src="@/assets/images/yeTip.svg" />
          </div>
          <div class="text">
            {{ content }}
          </div>
        </div>
        <slot></slot>
        <div class="btnmodel">
          <div class="sure" @click="configDelete()">{{ sureFont }}</div>
          <div @click="closeDeletedig">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'XimuDialog',
  props: {
    // 弹窗标题
    title: {
      type: String,
      default: '提示',
    },
    // 弹窗正文
    content: {
      type: String,
      default: '确认删除吗？',
    },
    // 弹窗显示状态
    deleteDialogVisible: {
      type: Boolean,
      default: false,
    },
    // 展示
    type: {
      type: String,
      default: 'red',
    },
    // 确认按钮文字
    sureFont: {
      type: String,
      default: '确认',
    },
    // 是否出现提示小图片
    showTipPic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeDeletedig() {
      // this.deleteDialogVisible = false;
      this.$emit('closeDeletedig'); // 父组件绑定的事件
    },
    configDelete() {
      // this.deleteDialogVisible = false;
      this.$emit('configDeletedig'); // 父组件绑定的事件
    },
  },
};
</script>

<style scoped>
.configmodel /deep/ .el-dialog {
  margin: 0px !important;
  top: 50%;
  left: 50%;
  border-radius: 0;
  transform: translate(-50%, -50%);
}

.configmodel /deep/ .el-dialog__header {
  width: 480px;
  height: 60px;
  background: #f2f2f2;
  padding: 20px;
  box-sizing: border-box;
}

.configmodel /deep/ .el-dialog__title {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #111111;
}

.configmodel /deep/ .el-dialog__body {
  padding: 20px 20px 30px 20px;
}

.content {
  display: flex;
  justify-content: left;
}

.imgmodel {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.text {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}

.btnmodel {
  margin-top: 20px;
  display: flex;
  justify-content: right;
}

.btnmodel div {
  padding: 8px 15px;
  line-height: 22px;
  color: #666;
  border: 1px solid #ccc;
  cursor: pointer;
}

.btnmodel .sure {
  margin-right: 20px;
  color: #fff;
  background-color: #026bff;
  border: 1px solid #026bff;
}

/* .btnmodel > :first-child {
  width: 60px;
  height: 40px;
  background: #ff7802;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
} */

/* .btnmodel > :last-child {
  width: 60px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cccccc;
  cursor: pointer;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #606266;
  line-height: 40px;
  text-align: center;
} */

.configmodel /deep/.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #909399 !important;
}
.configmodel /deep/ .el-dialog__headerbtn:hover .el-dialog__close {
  color: #909399 !important;
}
.configmodel /deep/ .el-dialog__headerbtn .el-dialog__close {
  font-size: 24px;
}
</style>
