import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss';
import './styles/element-ui.scss';
import store from '@/store/index.js';
import './styles/global.css';
import './utils/fonts/iconfont.css';

//全局混入 mixin
import Mixins from './utils/mixin/mixin.js';
Vue.mixin(Mixins);

Vue.use(ElementUI);

// 混入全局组件
import { initGlobalComponents } from './utils/install.js';
initGlobalComponents(); // 注册全局组件

new Vue({
  router,
  store,
    render: (h) => h(App),
}).$mount('#app');
