<template>
  <div class="header-max">
    <div class="head-box">
      <div class="logo" @click="gourl">
        <img src="@/assets/logo.svg" alt="">
      </div>
      <div class="head-text margin-left32">中文</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headerNav',
  data() {
    return {};
  },
  methods: {
    gourl() {
      this.$router.push('/login');
    },
  },
};
</script>
<style lang="scss" scoped>
.header-max {
  display: flex;
  height: 86px;
  border-bottom: 1px solid #eaebf0;

  .head-box {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    // line-height: 86px;

    .logo {
      height: 40px;
      cursor: pointer;

      img {
        object-fit: contain;
        height: 100%;
      }
    }
    .margin-left32 {
      margin-left: 32px;
    }
  }
}
</style>
