<template>
  <div class="tip">
    <span>
      <img class="tip_img" src="@/assets/info.svg" alt="" />
    </span>
    <span class="tip_lab" v-show="isShow">温馨提示：</span>
    <!-- 只有一条的 -->
    <div class="tip_text1">
      <slot name="first"></slot>
    </div>
    <!-- 有多条的
      例：1.xxxx
          2.xxxx
     -->
    <div class="tip_text2">
      <slot name="second"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
// 提示
.tip {
  width: 1160px;
  background: #f7f7f7;
  border-radius: 0px 0px 0px 0px;
  opacity: 0.8;
  margin: 0 auto;
  display: flex;
  padding: 10px 20px;

  .tip_lab {
    font-size: 14px;
    color: #4d4d4d;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    margin-left: 10px;
    flex-shrink: 0;
  }

  .tip_text1 {
    font-size: 12px;
    color: #808080;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    margin-left: 3px;
    line-height: 21px;
  }
  .tip_text2 {
    font-size: 12px;
    color: #808080;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    margin-left: 3px;
    line-height: 21px;
  }
  .tip_img {
    margin-top: 2px;
  }
}
</style>
